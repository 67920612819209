
  .layout {
    width: 100%;
  }

  .layout-sidebar {
    padding-right: 0;
    display: flex;
    flex-direction: column;
    min-width: 10em;
  }
  .layout-leftsidebar {
    padding-right: 0;
    display: flex;
    flex-direction: column;
    width: 30%;
  }

  .layout-navbar {
    flex-direction: row;
    gap: 5px;
    width: 100%;
  }
  .layout-topbar,.layout-header,.layout-main-header,.layout-main-footer {
    padding: 32px 24px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
  }
  .layout-content {
    display: flex;
  }
  .layout-body {
    display: flex;
    padding: 0 24px;
  }
  .layout-dialog {
    width: 100%;
    padding: 0 32px 0 32px;
  }
  .layout-main {
    width: 70%;
    padding: 0 16px 0 16px;
  }
  .layout-main-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 10px 0 10px 0
 }

 .layout-main.dialog {
  width: 100%;
}
.layout-topbar.dialog {
    padding: 0px 32px 0px 32px;
 }
 .layout-main-footer.dialog {
  padding: 10px 24px;
  justify-content: flex-end;
}
.layout-main-body.dialog {
  height:60vh;
  overflow:auto;
}
.layout-main.alert {
  min-width: 300px;
}
.layout-main-body.alert {
  min-height:200px;
  overflow:auto;
}
