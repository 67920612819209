/* Layout */
.layout-menu .layout-main {
  width: 100%;
  position: relative;
}

.layout-menu .layout-leftsidebar {
  min-width: 20%;
  width: initial;
  position: relative;
  z-index: 999;
  padding: 0 0.25rem;
}
