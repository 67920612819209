@font-face {
  font-family: 'CarbonIcons';
  src: url('./fonts/sage/carbon-icons-webfont.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SageFont';
  src: url('./fonts/sage/sageFont/Sage_UI-Regular.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'SageFont';
  src: url('./fonts/sage/sageFont/Sage_Text-Light_Italic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'SageFont';
  src: url('./fonts/sage/sageFont/Sage_UI-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SageFont';
  src: url('./fonts/sage/sageFont/Sage_Text-Regular_Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'SageFont';
  src: url('./fonts/sage/sageFont/Sage_UI-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'SageFont';
  src: url('./fonts/sage/sageFont/Sage_Text-Medium_Italic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'SageFont';
  src: url('./fonts/sage/sageFont/Sage_UI-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'SageFont';
  src: url('./fonts/sage/sageFont/Sage_Text-Bold_Italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'SageFont';
  src: url('./fonts/sage/sageFont/Sage_UI-Bold.woff2') format('woff2');
  font-weight: 900;
}

/* Je ne sais pas à quoi sont sensé servir ces 3 cas
@font-face {
  font-family: 'SageFont';
  src: url('./fonts/sage/sageFont/Sage_Text-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'SageFont';
  src: url('./fonts/sage/sageFont/Sage_Text-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'SageFont';
  src: url('./fonts/sage/sageFont/Sage_Text-Bold.woff2') format('woff2');
}
*/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: SageFont, Arial, Helvetica, sans-serif;
}

html {
  height:100vh;
}

body {
  height: 100%;
  color: white;
  font-family: SageFont, Arial, Helvetica, sans-serif;
  font-size:"14px";
  font-weight:"400";
}

.container {
  margin: auto;
  padding: 0 20px;
  background: linear-gradient(180deg,#fcfcfc 0,#fff);
}

.layout-layout {
  height: 100%;
}

.layout-sidebar > .facet {
  margin-top: 32px;
  width: 100%;
}

div[role=presentation__] {
  min-height: 30px;
}
div[role=dialog__] {
  min-width: 400px;
}
span[data-component=icon][disabled] {
  background-color: unset;
}
span[data-component=icon][disabled]:hover {
 background-color: unset;
}

.dialog {
  width:100%;
}

button[role='button'] {
  margin: 0px 5px;
}

:root {
  --toastify-toast-width: 328px;
}
