
/*
 Special icons
 TODO build a font
*/

@font-face {
    font-family: 'sageFrp1000';
    src: url('./fonts/sage/sageFrp1000.eot') format('embedded-opentype');
    src: url('./fonts/sage/sageFrp1000.svg') format('svg');
    src: url('./fonts/sage/sageFrp1000.woff') format('woff');
    src: url('./fonts/sage/sageFrp1000.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }


  span[data-component='icon'][data-element='compile']:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "sageFrp1000";
    content: "\e901";
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    vertical-align: middle;
    display: block;
  }

  span[data-component='icon'][data-element='addselect']:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "sageFrp1000";
    content: "\e900";
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    vertical-align: middle;
    display: block;
  }

  div[data-component="dialog"]:not([role="alertdialog"]) > div:not(:first-child) {
    padding: 0;
  }
  div[data-component="dialog"]:not([role="alertdialog"]) > div:not(:first-child) > div {
    padding: 0;
  }
  div[data-component="sidebar-header"] {
    background-color: #E6EBED;
  }
  div[data-element="sidebar-content"] {
    padding: 0;
  }
